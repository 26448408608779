// DotNavigation.jsx
import React from "react";
import IconButton from "@mui/material/IconButton";
import LensIcon from "@mui/icons-material/Lens";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import sectionPaths from "./sectionPaths";

const useStyles = makeStyles({
  containerStyle: {
    position: "fixed",
    top: "45%",
    left: "10px",
    transform: "translateY(-50%)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    paddingLeft: "57px",
    "@media (max-width: 991px)": {
      display: "none !important", // This hides the DotNavigation on screens smaller than 991px
    },
  },
});

const DotNavigation = ({ activeSection, onSectionChange }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleDotClick = (path) => {
    onSectionChange(path);
    navigate(path);
  };

  return (
    <div className={classes?.containerStyle}>
      {sectionPaths.map((section) => (
        <IconButton
          key={section.id}
          onClick={() => handleDotClick(section.path)}
          color="primary"
          aria-label={`Go to ${section.title} section`}
        >
          {activeSection === section.path ? (
            <LensIcon
              style={{
                width: "10px",
                height: "10px",
                padding: "0px",
                outlineOffset: "5px",
                backgroundColor: "#ba9dff",
                outline: "2px solid #ba9dff",

                borderRadius: "50%",
                color: "#ba9dff",
              }}
            />
          ) : (
            <PanoramaFishEyeIcon
              style={{
                backgroundColor: "black",
                borderRadius: "50%",
                color: "black",
                width: "20px",
                height: "20px",
              }}
            />
          )}
        </IconButton>
      ))}
    </div>
  );
};

export default DotNavigation;
