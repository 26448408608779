import React, { useMemo } from 'react';
import { Box, Button, Typography, Grid, useMediaQuery, createTheme, ThemeProvider } from '@mui/material';
import { styled } from '@mui/system';
import ExplorSolutionButton from '../component/ExplorSolutionButton';
import { Helmet } from 'react-helmet';


const theme = createTheme({
    // You can customize your theme here
});

const RootBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    // padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper, // Adjust the background color as needed
    // height: '100vh', // Adjust height to fill the screen vertically
    display: 'flex',
    alignItems: 'center', // This aligns items vertically center
    justifyContent: 'center', // This aligns items horizontally center
}));

const ContentBox = styled(Box)(({ theme }) => ({
    // Add styles for the content box if needed
    textAlign: "start",
    width: "100%"
}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    backgroundColor: " rgba(0, 0, 0, 0)",
    background: 'linear-gradient(to right, #e4449b 2%, #fa9e25 67%)',
    backgroundClip: "text",
    marginTop: 0,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "40px",
    transition: "all .2s",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    color: "#373737",
    textAlign: "left",
    marginTop: 0,
    fontSize: "21px",
    fontWeight: 400,
    lineHeight: "32px !important",
}));

const FeatureList = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    alignItems: "center",
    padding: 0,
    '& li': {
        marginBottom: "5px",
        color: "#1e1e1e !important",
        webkitTextFillColor: "inherit",
        // backgroundImage: "url(https://assets-global.website-files.com/646e79866091ec5584c257d3/646e79866091ec5584c257fb_Group%20633.svg)",
        backgroundPosition: "0%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "24px",
        backgroundClip: "border-box",
        // marginBottom: "15px",
        marginLeft: 0,
        fontWeight: 400,
        lineHeight: "22px",
        listStyleType: "none",
        '& p': {
            alignItems: "normal !important"
        }
    },
    '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        fontSize: "18px",
    },
}));



const ImageContainer = styled(Grid)(({ theme }) => ({
    position: 'relative',
    textAlign: "end",

    // Use the image as a background here
}));
const LeftDiv = styled(Box)(({ theme }) => ({

    // Use the image as a background here
}));

function HomePage() {

    const jsonLdData = useMemo(() => ({
        "@context": "https://schema.org",
        "@type": "Medical Device, Artificial Intelligence",
        "name": "Scanbo",
        "alternateName": "Scanbo India Private Limited",
        "url": "https://www.scanbo.com/",
        "logo": "https://uploads-ssl.webflow.com/646e79866091ec5584c257d3/646e79866091ec5584c257fc_scanbo-vertical-logo512x512%20(1)%201.svg",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9372600050",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "IN",
            "availableLanguage": "en"
        },
        "sameAs": [
            "https://www.facebook.com/ScanboOfficial",
            "https://www.twitter.com/ScanboOfficial",
            "https://www.instagram.com/scanboofficial/",
            "https://www.linkedin.com/company/scanbo"
        ]
    }), []); // Dependencies array is empty as jsonLdData doesn't depend on props or state
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Scanbo - Medical Diagnostics In The Palm of Your Hand</title>
                <meta name="description" content="Get health reports under 5 minutes with Scanbo. Monitor your well-being and track vital signs effortlessly. Start prioritizing your health today!" />
                {/* Include the JSON-LD structured data in the head */}
                <script type="application/ld+json">
                    {JSON.stringify(jsonLdData)}
                </script>
            </Helmet>
            <RootBox>
                <ContentBox>
                    <Grid container spacing={4} justifyContent="flex-end" alignItems="flex-start" sx={{
                        marginTop: "20px",
                        // flexWrap: "wrap-reverse"

                    }}>
                        <Grid item xs={12} sm={12} lg={7} md={7} style={{
                            paddingLeft: "13.5%"
                        }}>
                            <LeftDiv>
                                <Title variant="h3" color="primary">
                                    Shaping the Future of Diagnostic Innovations
                                </Title>
                                <Subtitle variant="subtitle1" className='sub-1-home'>
                                    Swift, Precise, and Affordable Point-of-Care Innovations on a Single, Ultra-Portable Platform!
                                </Subtitle>
                                <Typography fontSize="18px" fontWeight="bold" className='home-desc'>
                                    MEDICAL DIAGNOSTICS IN THE PALM OF YOUR HAND
                                </Typography>
                                <FeatureList>
                                    <li>
                                        <Typography variant="body1">
                                            <span>
                                                <img src='/logos/mark.svg' />
                                            </span> AI-powered, ultra-portable diagnostic solutions
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <span> <img src='/logos/mark.svg' /></span> Revolutionizing point-of-care diagnostics
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <span> <img src='/logos/mark.svg' /></span> Faster, more convenient primary care triage
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <span> <img src='/logos/mark.svg' /></span> Empowering healthcare providers, improving outcomes
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <span> <img src='/logos/mark.svg' /></span> Advancing diagnostics for global well-being and accessibility
                                        </Typography>
                                    </li>
                                    {/* ... more list items */}
                                </FeatureList>
                                <Box display="flex" alignItems="start" justifyContent="left" >
                                    <ExplorSolutionButton variant="contained" >
                                        EXPLORE SOLUTIONS
                                    </ExplorSolutionButton>

                                </Box>
                            </LeftDiv>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={5} md={5}>
                            <ImageContainer className={ImageContainer}>
                                {/* Place your image here */}
                                <img src='/images/home.png' style={{ maxWidth: "100%", height: "62vh" }} />
                            </ImageContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>

                            <Box marginLeft="8px" sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '80px',

                                marginLeft: '90px',
                                height: '100px',
                                // position: "relative",
                                // right: "22%",

                            }}>
                                <img src='/logos/iso.png' alt='Scanbo ISO 27001 Certification' height="100px" />
                                <img src='/logos/hipaa-compliant.jpg' alt='Scanbo HIPAA Certification' height="100px" />
                                <img src='/logos/type2.png' alt='Scanbo SOC2 Certification' height="100px" />
                                <img src='/logos/eu-gdpr.jpg' alt='Scanbo GDPR compliance Certification' height="100px" />
                            </Box>
                        </Grid>

                    </Grid>

                </ContentBox>
            </RootBox>
        </ThemeProvider >
    );
}

export default HomePage;
