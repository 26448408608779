import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import { makeStyles } from "@mui/styles";
import { Await, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { Helmet } from "react-helmet";

const category = [
  {
    value: "patient",
    label: "Patient",
  },
  {
    value: "doctor",
    label: "Doctor",
  },
  {
    value: "hospital",
    label: "Hospital",
  },
  {
    value: "senior/homeCareService",
    label: "Senior/Home Care Service",
  },
  {
    value: "nonProfit/communityWorker",
    label: "Non-Profit/Community Worker",
  },
  {
    value: "government",
    label: "Government",
  },
  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "other",
    label: "Other",
  },
];

const useStyles = makeStyles((theme) => ({
  countryHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "10px",
    textAlign: "left",
  },
  mainDiv: {
    "@media (max-width: 991px)": {
      marginLeft: "0px !important", // This hides the DotNavigation on screens smaller than 991px
    },
  },
  textDiv: {
    padding: "12px !important",
    display: "flex",
    width: "84%",
    alignItems: "center",
    backgroundColor: "#fafafa !important",
    border: "1.12px solid #cfcfcf !important",
    borderRadius: "10px !important",
  },
  flagIcon: {
    maxWidth: "41px !important",
    height: "28px !important",
    animation: "leftToRight 1s ease forwards",
  },
  contactText: {
    color: "#1e1e1e !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
  smalContactText: {
    fontSize: " 12px !important",
    fontWeight: "300",

    lineHeight: "15px",
    color: "#636363 !important",
  },
  submitButton: {
    backgroundColor: "#000 !important",
    marginTop: "20px !important",
    padding: "15px 60px !important",
    fontWeight: "400 !important",
    color: "#fff !important",
    lineHeight: "inherit !important",
    cursor: "pointer !important",
    border: "0 !important",
    borderRadius: "0 !important",
    animation: "fadeIn 1.5s ease forwards" /* Apply the animation */,
  },
  Icon: {
    backgroundColor: " #fff",
    border: "1px solid #eee",
    borderRadius: "8px",
    alignSelf: "center",
    marginTop: 0,
    marginRight: "10px",
    padding: "3px",
  },
  thankYou: {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f3f3f3",
    borderRadius: "10PX",
    width: "90%",
  },

  TextField: {
    "& div fieldset": {
      padding: " 0px !important",
      borderRadius: "0px !important",
      border: "1px #000 !important",
      borderBottom: "1.5px solid #d9d9d9 !important",
      padding: "10.5px 0px !important",
    },
    "& div input, select": {
      padding: "10.5px 0px !important",
    },
  },
}));

const ContactPage = () => {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Medical Device, Artificial Intelligence",
    name: "Scanbo",
    alternateName: "Scanbo India Private Limited",
    url: "https://www.scanbo.com/contactus",
    logo: "https://uploads-ssl.webflow.com/646e79866091ec5584c257d3/646e79866091ec5584c257fc_scanbo-vertical-logo512x512%20(1)%201.svg",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91 9372600050",
      contactType: "customer service",
      contactOption: "TollFree",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/ScanboOfficial",
      "https://www.twitter.com/ScanboOfficial",
      "https://www.instagram.com/scanboofficial/",
      "https://www.linkedin.com/company/scanbo",
    ],
  };
  const classes = useStyles();

  const form = useRef();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    projectDetails: "",
    category: "",
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // Add this line

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading before the async operation
    try {
      const response = await axios.post(
        "https://mail-scanbo.vercel.app/send-email",
        formValues
      );
      console.log("Email sent successfully", response.data);
      setSubmissionSuccess(true);
      setLoading(false); // Stop loading after the operation is successful
      // Optionally reset form or set success message
    } catch (error) {
      console.error("Error sending email", error);
      setLoading(false); // Stop loading if there is an error
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Scanbo - Contact Scanbo India for Innovative Healthcare Solutions
        </title>
        <meta
          name="description"
          content="Connect with Scanbo Canada for innovative healthcare solutions. Reach out to our friendly team and unlock your potential to transform patient care."
        />
        {/* JSON-LD structured data */}
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <Container
        maxWidth="100%"
        sx={{ margin: "20px auto 0", paddingLeft: "5% !important" }}
      >
        <Grid container spacing={4} lg={12} justifyContent="space-between">
          {/* Contact Info Section */}
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            textAlign="start"
            marginLeft="9%"
            className={classes.mainDiv}
          >
            <Typography
              gutterBottom
              fontSize="16px"
              // color="#373737"
              fontWeight="400"
            >
              CONTACT US
            </Typography>
            <Typography
              gutterBottom
              fontSize="24px"
              color="#373737"
              className="contact-desc"
              fontWeight="400"
            >
              Connect with us to get
              <br /> more information
            </Typography>

            {/* Chat and Mail Options */}
            <Box
              className="contact-info"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,

                // width: "66%",
              }}
            >
              <Paper variant="outlined" className={classes.textDiv}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.Icon}>
                    <img src="/images/chat.svg" />
                  </Box>
                  <Box textAlign="start">
                    <Link
                      className="link"
                      to="https://api.whatsapp.com/send/?phone=919372600050&text&type=phone_number&app_absent=0"
                    >
                      <Typography className={classes.contactText}>
                        Chat with us
                      </Typography>
                      <Typography className={classes.smalContactText}>
                        our friendly team is here to help
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Paper>
              <Paper variant="outlined" className={classes.textDiv}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.Icon}>
                    <img src="/images/mail.svg" />
                  </Box>
                  <Box textAlign="start">
                    <Link className="link" to="mailto:info@scanbo.com">
                      <Typography className={classes.contactText}>
                        Mail us
                      </Typography>
                      <Typography className={classes.smalContactText}>
                        care@scanbo.com
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>

            {/* Google Maps Embed */}
            {/* Replace the src with your actual Google Maps link */}
            <Grid sx={{ mt: 2 }} xl={8}>
              <iframe
                className="contact-info"
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10430.070998616047!2d-122.85378700000001!3d49.190737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485dbb6619877ff%3A0x3a79ffff57d1f0a4!2sScanbo%20-%20MEDICAL%20DIAGNOSTICS%20IN%20THE%20PALM%20OF%20YOUR%20HAND!5e0!3m2!1sen!2sin!4v1704450804291!5m2!1sen!2sin"
                width="300"
                height="200"
                style={{
                  border: "0",
                  width: "300px",
                  height: "200px",
                  borderRadius: "15px",
                  border: "1.12px solid #cfcfcf !important",
                }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Grid>
          </Grid>

          {/* Form Section */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            textAlign="start"
            justifyContent="start"
          >
            <Typography
              gutterBottom
              className="form-heading"
              fontSize="42px"
              color="#373737"
              letterSpacing="0.00938em"
            >
              Let's unlock your potential together.
            </Typography>

            <Typography variant="subtitle1" gutterBottom className="form-title">
              Tell us more about yourself
            </Typography>
            {submissionSuccess ? (
              <Box className={classes.thankYou}>
                <Typography variant="h6" gutterBottom color="green">
                  Thank you! Your submission has been received!
                </Typography>
              </Box>
            ) : (
              <Box
                component="form"
                ref={form}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  name="name"
                  fullWidth
                  className={classes.TextField}
                  required
                  placeholder="Name"
                  margin="normal"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
                <TextField
                  name="email"
                  fullWidth
                  type="email"
                  className={classes.TextField}
                  required
                  placeholder="Email"
                  margin="normal"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
                <TextField
                  name="projectDetails"
                  fullWidth
                  required
                  className={classes.TextField}
                  placeholder="Tell us a little about your project"
                  margin="normal"
                  value={formValues.projectDetails}
                  onChange={handleInputChange}
                />
                <TextField
                  name="category"
                  fullWidth
                  select
                  required
                  className={classes.TextField}
                  margin="normal"
                  value={formValues.category}
                  onChange={handleInputChange}
                  placeholder="Select a category"
                  SelectProps={{ native: true }}
                >
                  <option value="" disabled selected hidden>
                    Select a category
                  </option>
                  {category.map((option) => (
                    <>
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    </>
                  ))}

                  {/* Add other categories here */}
                </TextField>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  className={classes.submitButton}
                >
                  {loading ? "Please wait..." : "Send Message"}
                </Button>
              </Box>
            )}
            {/* Contact Form */}
          </Grid>
        </Grid>
        <Grid
          spacing={4}
          lg={6}
          justifyContent="start"
          alignItems="start"
          mt={8}
        >
          <Typography variant="h4" gutterBottom>
            Our Offices
          </Typography>
        </Grid>
        <Grid
          container
          spacing={4}
          lg={12}
          xl={9}
          md={12}
          justifyContent="center"
          margin="auto"
          mt={8}
          textAlign="start"
        >
          <Grid item xs={12} md={4} lg={4} borderRight="0.5px solid #161616">
            <Box className={classes.countryHeader}>
              <Avatar
                src="/images/canada.png"
                className={classes.flagIcon}
                variant="square"
              />
              <Typography
                component="span"
                marginLeft="10px"
                fontSize="24px"
                fontWeight="500"
                line-height="30px"
                className="india"
              >
                Canada- Head Office
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              fontSize="18px"
              fontWeight="500"
            >
              Scanbo Technologies Inc.
            </Typography>
            <Typography variant="body1" gutterBottom fontSize="13px">
              13303 Central Avenue
              <br />
              Surrey BC V3T0K6, Canada
              <br />
            </Typography>
            <Typography fontSize="13px" variant="body1">
              Ph: +1-250-2-SCANBO (722620)
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} borderRight="0.5px solid #161616">
            <Box className={classes.countryHeader}>
              <Avatar
                src="/images/singapaur.png"
                className={classes.flagIcon}
                variant="square"
                style={{ border: "1px solid #b9b9b9" }}
              />
              <Typography
                component="span"
                marginLeft="10px"
                fontSize="24px"
                fontWeight="500"
                line-height="30px"
                className="india"
              >
                Singapore
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              fontSize="18px"
              fontWeight="500"
            >
              Scanbo Technologies Pte Ltd.
            </Typography>
            <Typography variant="body1" gutterBottom fontSize="13px">
              531 Upper Cross Street #02-11,
              <br />
              Hong Lim Complex,
              <br />
              Singapore 050531
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box className={classes.countryHeader}>
              <Avatar
                src="/images/india.png"
                className={classes.flagIcon}
                variant="square"
              />
              <Typography
                component="span"
                marginLeft="10px"
                fontSize="24px"
                fontWeight="500"
                line-height="30px"
                className="india"
              >
                India
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              fontSize="18px"
              fontWeight="500"
            >
              Scanbo India Private Limited
            </Typography>
            <Typography variant="body1" gutterBottom fontSize="13px">
              B 403-406 Pragati Empire &#40;IT Park&#41;,
              <br />
              Sudama chowk road, Surat
              <br />
              Gujarat, India 394105
            </Typography>
            <Typography fontSize="13px" variant="body1">
              Ph: +91 93726 00050
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactPage;
