import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person"; // Placeholder for the avatar icon

const StyledCard = styled(Card)(({ theme, bgcolor }) => ({
  maxWidth: 345,
  borderRadius: 16,
  textAlign: "center",
  position: "relative",
  overflow: "visible", // This will show the Avatar outside the Card
  backgroundColor: bgcolor, // The background color for the Card
  boxShadow: "none",
  padding: " 75px 15px 56px",
  //   marginBottom: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 70,
  height: 70,
  background: "red",
  outline: " 13px solid #fff",
  position: "absolute",
  top: -36, // Move it up by half the height
  left: "calc(50% - 36px)", // Center it horizontally
  //   boxShadow: theme.shadows[3],
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: "auto",
}));

export const CustomCard = ({
  title,
  description,
  bgColor,
  icon,
  avatarBgColor,
}) => (
  <StyledCard bgcolor={bgColor} className="box">
    <StyledAvatar style={{ backgroundColor: avatarBgColor }}>
      <img src={icon} alt="icon" style={{ width: "47px" }} />
    </StyledAvatar>
    <StyledCardContent style={{ padding: "0px" }}>
      {/* <Typography variant="h6" component="h3">
        {title}
      </Typography> */}
      <Typography variant="body2">{description}</Typography>
    </StyledCardContent>
  </StyledCard>
);
