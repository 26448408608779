import React, { useRef, useEffect } from 'react';
import { CustomCard } from '../component/CardComonent';
import { styled } from "@mui/system";
import {
    Box,
    Button,
    Typography,
    Grid,
    createTheme,
    ThemeProvider,
} from "@mui/material";
import ExploreSolutionButton from '../component/ExplorSolutionButton';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const theme = createTheme({
    // You can customize your theme here
});

const RootBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    // padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper, // Adjust the background color as needed
    // height: '100vh', // Adjust height to fill the screen vertically
    display: "flex",
    alignItems: "center", // This aligns items vertically center
    justifyContent: "center", // This aligns items horizontally center
}));

const ContentBox = styled(Box)(({ theme }) => ({
    // Add styles for the content box if needed
    textAlign: "start",
    width: "100%",

}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    backgroundColor: " rgba(0, 0, 0, 0)",

    backgroundImage: "linear-gradient(to right, #e4449b 2%, #fa9e25 55%)",
    backgroundClip: "text",
    marginTop: 0,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "40px",


    // transition: all .2s
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    color: "#373737",
    textAlign: "left",
    marginTop: 0,
    fontSize: "21px",
    letterSpacing: "0.00938em",
    fontWeight: 400,
    lineHeight: "32px !important",
}));

const ExploreButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    color: "#34333c",
    webkitTextStrokeColor: "#1e1e1e",
    backgroundColor: "#fff",
    border: "1px solid #373737",
    borderRadius: "56px",
    marginTop: "30px",
    padding: "13px 20px 10px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22.5px",
    boxShadow: "none",
}));

const ImageContainer = styled(Grid)(({ theme }) => ({
    position: "relative",
    textAlign: "end",
    // Use the image as a background here
}));
const LeftDiv = styled(Box)(({ theme }) => ({
    // Use the image as a background here
}));

const BoxContainer = styled(Grid)(({ theme }) => ({
    width: "95%",
    /* grid-row-gap: 50px; */
    gridTemplateRows: "auto",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "100px",
    gridRowGap: "100px",
    borderRadius: "57px",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: " 0 auto 40px",
    paddingTop: "50px",
    paddingBottom: "20px",
    paddingLeft: 0,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
        "& div": {
            gridTemplateColumns: "1fr",
        }
    },

    // Use the image as a background here
}));
const MainContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    textAlign: 'center', // Center align the text
}));

// Update the MainTitle to match your gradient text
const MainTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 900,
    display: 'inline', // Make the title inline to fit within the surrounding text
    background: 'linear-gradient(to right, #20bedf, #d79ae1)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '26px',
    [theme.breakpoints.down('md')]: {
        fontSize: '32px', // Smaller font size on mobile
    },
}));



const cardData = [
    {
        id: 1,
        title: "Boost your revenue",
        description: "Boost your revenue with our AI-enhanced SCANBO diagnostic platform. It swiftly analyzes vital signs for precise results, enhancing patient outcomes and throughput for peak efficiency.",
        bgColor: '#e5ffce',
        icon: "/avtar/box1.png",
        avatarBgColor: '#e5ffce'
    },
    {
        id: 2,
        title: "Streamline hospital operations",
        description: "Streamline hospital operations and reduce patient wait times with SCANBO’s point-of-care diagnostic tools. Efficiently manage vital signs at reception for fast, accurate data in outpatient and inpatient care, saving specialist time and optimizing resources.",
        bgColor: '#e2f3ff',
        icon: "/avtar/box2.svg",
        avatarBgColor: '#e2f3ff'


    },
    {
        id: 3,
        title: "Boost your revenue",
        description: "Ensure immediate, precise care for seniors and homebound patients by using SCANBO’s portable diagnostic tech for instant vital signs assessments.",
        bgColor: '#f5f5f5',
        icon: "/avtar/box3.svg",
        avatarBgColor: '#f5f5f5'

    },
    {
        id: 4,
        title: "Streamline hospital operations",
        description: "Boost your community healthcare with SCANBO's digital platform, offering affordable vital signs diagnostics for improved access in underserved and remote areas.",
        bgColor: '#ffe2ee',
        icon: "/avtar/box4.svg",
        avatarBgColor: '#ffe2ee'

    },
    {
        id: 5,
        title: "Boost your revenue",
        description: "Adopt SCANBO’s scalable diagnostic solutions for real-time, pin-code level visibility of vital signs, reducing manual errors, enhancing public health outcomes, and fortifying a resilient healthcare infrastructure.",
        bgColor: '#ffe6b5',
        icon: "/avtar/box5.svg",
        avatarBgColor: '#ffe6b5'

    },
    {
        id: 6,
        title: "Streamline hospital operations",
        description: "Optimize claims processing and risk assessment with SCANBO’s AI diagnostic platform, delivering real-time vital signs data for efficient policy underwriting and increased customer satisfaction.",
        bgColor: '#fff8b9',
        icon: "/avtar/box6.svg",
        avatarBgColor: '#fff8b9'


    },
    // ...more cards
];

const Solution = () => {

    const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "Medical Device, Artificial Intelligence",
        "name": "Scanbo",
        "alternateName": "Scanbo India Private Limited",
        "url": "https://www.scanbo.com/solutions",
        "logo": "https://uploads-ssl.webflow.com/646e79866091ec5584c257d3/646e79866091ec5584c257fc_scanbo-vertical-logo512x512%20(1)%201.svg",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9372600050",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "IN",
            "availableLanguage": "en"
        },
        "sameAs": [
            "https://www.facebook.com/ScanboOfficial",
            "https://www.twitter.com/ScanboOfficial",
            "https://www.instagram.com/scanboofficial/",
            "https://www.linkedin.com/company/scanbo"
        ]
    };

    const scrollToRef = useRef(null); // Create a ref for the div
    const location = useLocation();

    useEffect(() => {
        // If coming from the Home component with the specific state
        if (location.state?.scrollToSolution && scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);
    // Function to execute on button click to scroll to the div

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Scanbo - Innovative Biotech Solutions to Propel Your Medical Diagnoses Forward</title>
                <meta name="description" content="Discover tailor made healthcare solutions within a few fingertips action. Enhance healthcare productivity with our point-of-care platform and celebrate your patient happiness." />
                {/* Include the JSON-LD structured data in the head */}
                <script type="application/ld+json">
                    {JSON.stringify(jsonLdData)}
                </script>
            </Helmet>
            <RootBox>
                <ContentBox>
                    <Grid
                        container
                        lg={12}


                        sx={{
                            marginTop: "20px",
                            flexWrap: "wrap-reverse"
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            lg={7}
                            md={8}
                            style={{
                                paddingLeft: "11.45%",
                                marginTop: "2%"
                            }}
                        >
                            <LeftDiv>
                                <Title variant="h3" color="primary">
                                    SOLUTIONS
                                </Title>
                                <Subtitle variant="subtitle1" className='solution-desc'>
                                    To transform primary care by providing a handheld AI-driven medical diagnostic device that allows first-level screening by delivering test results in under 5 minutes.
                                </Subtitle>

                                <ExploreSolutionButton variant="contained" color="primary" >
                                    EXPLORE SOLUTIONS
                                </ExploreSolutionButton>
                            </LeftDiv>
                        </Grid>
                        <Grid item lg={5} md={4}>
                            <img src="/images/solution.png" className='solution-img' style={{
                                maxWidth: "80%",
                                marginTop: "-10px",
                                paddingRight: "20px",
                            }} />

                        </Grid>
                    </Grid>

                    <Grid container xl={6} lg={9} md={10} justifyContent="center" alignItems="center" sx={{
                        margin: "0 auto",
                        marginTop: "2%"
                    }}>
                        <MainContainer className='box-heading' >
                            <Typography component="span" fontSize="26px" color="#494949" >
                                Join us in making {' '}
                                <MainTitle component="span">healthcare, efficient, effective and accessible</MainTitle>
                                {' '} for all with the Future of Diagnostic Solutions.
                            </Typography>

                        </MainContainer>
                        <Grid item spacing={4} justifyContent="center" >
                            <Grid textAlign="center" >
                                <img src='/images/line.png' style={{
                                    width: "65%",
                                    textAlign: "center"
                                }} />
                            </Grid>
                            <BoxContainer ref={scrollToRef}>
                                <Grid rowGap="50px" columnGap="16px" gridTemplateRows="auto" gridTemplateColumns="1fr 1fr 1fr" display="grid" sm={12} md={12}>
                                    {cardData.map((card) => (
                                        // <Grid key={card.id} >
                                        <CustomCard {...card} />
                                        // </Grid>
                                    ))}
                                </Grid>
                            </BoxContainer>
                        </Grid>
                    </Grid>

                </ContentBox>
            </RootBox>
        </ThemeProvider>

    );
};

export default Solution;
