import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: "20px auto 0 !important",
    maxWidth: "90%",
    paddingBottom: "70px !important",
  },
  header: {
    margin: theme.spacing(4, 0),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: " rgba(0, 0, 0, 0)",
    background: "linear-gradient(to right, #ff5a5a, #cb5555)",
    backgroundClip: "text",
    marginTop: 0,
    lineHeight: "44px",
    fontWeight: "700 !important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "25px !important",
  },
  section: {
    margin: theme.spacing(2, 0),
    width: "100%",
    maxWidth: "100%",
    color: "#373737",
    textAlign: "left",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "32px !important",
    display: "block",
  },
  disclaimer: {
    marginTop: "20px",
  },
}));

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        DISCLAIMER OF LIABILITY:
      </Typography>
      <Box className={classes.disclaimer}>
        <Typography variant="body1" className={classes.section}>
          In no event shall Scanbo™ be liable for any damages (including,
          without limitation, lost profits, business interruption, or lost
          information) arising out of your use of or inability to use the
          product, even if Scanbo™ has been advised of the possibility of such
          damages. No distributor, dealer or other party is authorized to make
          any warranty on behalf of Scanbo™, or to assume for Scanbo™ any other
          liability with respect to its products, services, data storage and
          protection. Scanbo™ is not liable for any inaccurate, mistaken or
          false results that are shown on the products. Scanbo™ is not an
          alternative to any medical procedure or check-up that would require
          qualified doctors. To the extent that the product and the information
          and services are provided free of charge, we will not be liable for
          any loss or damage of any nature. We will not be liable to you in
          respect of any business losses, including (without limitation) loss of
          or damage to profits, income, revenue, use, production, anticipated
          savings, business, contracts, commercial opportunities or goodwill. We
          will not be liable to you in respect of any loss or corruption of any
          data, database or software. We will not be liable to you in respect of
          any special, indirect or consequential loss or damage.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          Except for the express warranty set forth herein, the product and
          software provided hereunder are provided "as is" and Scanbo™ makes no
          warranty as to its use or performance. Except for any warranty,
          condition, representation or term the extent to which cannot be
          excluded or limited by applicable law, Scanbo™ and its suppliers make
          no warranty, condition, representation, or term (express or implied,
          whether by statute, common law, custom, usage or otherwise) as to any
          matter including, without limitation, non-infringement of third party
          rights, merchantability, integration, or fitness for a particular
          purpose. You assume responsibility for selecting the product to
          achieve your intended results, and for the installation of, use of,
          and results obtained from the product and software. Without limiting
          the foregoing provisions, Scanbo™ makes no warranty that the equipment
          or software will be error-free or free from interruptions or other
          failures or that the software or product will meet your requirements.
          under no circumstances and under no legal theory, whether in tort,
          contract, or otherwise, shall Scanbo™ or its suppliers be liable to
          the purchaser or to any other person for damages for gross negligence
          of any character including, without limitation, damages for loss of
          goodwill, work stoppage, computer failure or malfunction, or for any
          other damage or loss. In no event shall Scanbo™ or its suppliers be
          liable for any damages in excess of the price paid for the product and
          software, even if Scanbo™, or its authorized partners or suppliers
          have been advised of the possibility of such damages.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          LIMITED RIGHT TO USE DATA; USE OF COOKIES
          <br /> User grants us the right to use, copy, store, transmit and
          display User data solely to the extent necessary to provide the
          Platform and associated maintenance, for purposes of ensuring
          security, and for the investigation of complaints. All data submitted
          by User to the Platform (User Data) will remain the sole property of
          User. Subject to the terms of this Agreement, we shall not disclose
          User Data or use User data for any purpose other than to provide the
          Platform and as set forth herein.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          The Platform uses "cookies", which are small text files placed on the
          User’s hard disk by a Web server. Cookies contain information that can
          later be read by a Web server in the domain that issued the cookie to
          the User. One of the primary purposes of cookies is to store the
          User’s preferences and other information on the User’s computer in
          order to save the User’s time by eliminating the need to repeatedly
          enter the same information and to display the User’s personalized
          content and targeted advertising on the User’s later visits to this
          Platform. When the User signs in the Platform named User account, we
          store the User’s unique ID number, and the time the User signed in, in
          an encrypted cookie on the User’s hard disk. This cookie allows the
          User to move from page to page at the Platform without having to sign
          in again on each page. When the User signs out, these cookies are
          deleted from the User’s computer. We also use cookies to improve the
          sign-in experience. User agrees that we may use Usage Statistics to
          provide Users with communications regarding Platform support, news and
          information regarding the Platform and services/products provided by
          us, except where such Users opt out of receiving such information.
        </Typography>

        {/* Repeat for each section of the disclaimer */}
      </Box>
      {/* Additional content if needed */}
    </div>
  );
};

export default Disclaimer;
