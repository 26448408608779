import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Using Material-UI's 'styled' utility to create a custom styled button
const StyledButton = styled(Button)({
  marginTop: "5px",
  color: "#34333c",
  webkitTextStrokeColor: "#1e1e1e",
  backgroundColor: "#fff",
  border: "1px solid #373737",
  borderRadius: "56px",
  padding: "13px 20px 10px",
  fontSize: "16px",
  transformStyle: "preserve-3d",
  fontWeight: "700 !important",
  lineHeight: "22.5px",
  boxShadow: "none",
  animation: "zoomIn 1s ease forwards",
  transform:
    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
  "&:hover": {
    backgroundColor: "black",
    color: "white",
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    // Set the hover color here
    // Reset the box shadow if you don't want any
  },
  // If you want to keep the box shadow on hover, you can uncomment the following line
  // boxShadow: '4px 2px 8px 0px rgba(95, 157, 231, 0.48) inset, -4px -2px 8px 0px rgba(95, 157, 231, 0.48) inset'
});

// Your button component
const ExploreSolutionButton = memo(function ExploreSolutionButton(props) {
  let navigate = useNavigate();

  const handleNavigateAndScroll = () => {
    navigate("/solutions", { state: { scrollToSolution: true } });
  };

  return (
    <StyledButton
      variant="contained"
      {...props}
      onClick={handleNavigateAndScroll}
    >
      {props.children}
    </StyledButton>
  );
});

export default ExploreSolutionButton;
