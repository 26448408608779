import React from 'react';
import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './Header/Header';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './Footer/Footer';
import HomePage from './HomePage/HomePage';
import ContactPage from './contactusPage/ContactPage';
import Solution from './SolutionPage/Solution';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadAppPage from './downloadPage/DownloadAppPage';
import Disclaimer from './Disclaimer/Disclaimer';
import PrivacyPolicy from './PrivacyPolicyPage/PrivacyPolicy';
import TermsOfUse from './termsOfUsePage/TermsOfUse';
import MainLayout from './component/MainLayout';
import NotFoundPage from './NotFoundPage/NotFoundPage';

const theme = createTheme({

  // ... your other theme overrides
});
const CaseSensitiveGuard = ({ children, exactPath }) => {
  const location = useLocation();
  if (location.pathname !== exactPath) {
    return <NotFoundPage />;
  }
  return children;
};

function App() {

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <MainLayout />
            <main>
              <Routes>
                <Route path="/" element={
                  <CaseSensitiveGuard exactPath="/">
                    <HomePage />
                  </CaseSensitiveGuard>
                } />

                <Route path="/contactus" element={
                  <CaseSensitiveGuard exactPath="/contactus">
                    <ContactPage />
                  </CaseSensitiveGuard>
                } />
                <Route path="/solutions" element={
                  <CaseSensitiveGuard exactPath="/solutions">
                    <Solution />
                  </CaseSensitiveGuard>
                } />
                <Route path="/download" element={
                  <CaseSensitiveGuard exactPath="/download">
                    <DownloadAppPage />
                  </CaseSensitiveGuard>
                } />
                <Route path="/disclaimer" element={
                  <CaseSensitiveGuard exactPath="/disclaimer">
                    <Disclaimer />
                  </CaseSensitiveGuard>
                } />
                <Route path="/privacy-policy" element={
                  <CaseSensitiveGuard exactPath="/privacy-policy">
                    <PrivacyPolicy />
                  </CaseSensitiveGuard>
                } />
                <Route path="/terms-of-use" element={
                  <CaseSensitiveGuard exactPath="/terms-of-use">
                    <TermsOfUse />
                  </CaseSensitiveGuard>
                } />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
