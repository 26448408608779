import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom'; // Import NavLink
import CopyrightIcon from '@mui/icons-material/Copyright';

import { Avatar, Grid } from '@mui/material';

const useStyles = makeStyles({
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: "90%",
        margin: "20px auto 0",
        padding: '20px 0',
        "@media (max-width: 780px)": {
            flexWrap: "wrap"
        },
    },
    link: {
        marginLeft: '20px !important',
        marginRight: '20px !important',
        textDecoration: "none !important",
        padding: "5px ",
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    activeLink: {
        marginLeft: '20px !important',
        marginRight: '20px !important',
        fontWeight: '400', // Make the active link bold
        textDecoration: 'none', // Underline the active link
        color: '#0082f3 !important', // Change the color to indicate active state
    },
    rightDiv: {
        justifyContent: "space-between",
        "@media (max-width: 780px)": {
            '& a': {
                width: "100% !important", // Smaller font size on mobile,
                textAlign: "start",
                marginLeft: "0px !important"
            },
        },
        "& a": {
            borderRight: "1px solid gray", /* Adjust the color and width as needed */
            paddingRight: "10px" /* Adjust for internal spacing if needed */,
            borderLeft: "1px solid gray", /* Adjust the color and width as needed */

        }
    },
    leftDiv: {
        "@media (max-width: 1220px)": {
            minWidth: "40% !important", // Smaller font size on mobile,
            textAlign: "start",
        },
        "@media (max-width: 780px)": {
            width: "100% !important", // Smaller font size on mobile,
            textAlign: "start",
        },
    },
    flagIcon: {
        maxWidth: "30px !important",
        height: "17px !important",
        objectFit: "cover",
        marginLeft: "10px",
        animation: "leftToRight 1s ease forwards",
    },

});

function Footer() {
    const classes = useStyles();

    return (
        <footer >

            <Grid className={classes.footer} lg={12} display="flex" justifyContent="space-between"  >
                <Grid lg={2} md={12} className={classes.leftDiv}>
                    <Typography variant="h1" color="#333" fontWeight="600" fontSize="16px" display="flex" alignItems="center" flexWrap="wrap">
                        <span>  < CopyrightIcon fontSize='1.3rem' style={{ marginRight: "5px" }} /> 2024 Scanbo Technologies Inc, Canada
                            <img
                                src="/images/canada.png"
                                className={classes.flagIcon}
                                variant="square"
                            /></span>
                    </Typography>
                </Grid>
                <Grid item lg={10} md={12} flexWrap="wrap" display="flex" justifyContent="space-between" className={classes.rightDiv} marginTop="10px">
                    <NavLink to="/disclaimer" color="inherit" className={({ isActive }) => isActive ? classes.activeLink : classes.link} >
                        Disclaimer
                    </NavLink>
                    <NavLink to="/privacy-policy" color="inherit" className={({ isActive }) => isActive ? classes.activeLink : classes.link}>
                        Privacy Policy
                    </NavLink>
                    <NavLink to="/terms-of-use" color="inherit" className={({ isActive }) => isActive ? classes.activeLink : classes.link}>
                        Terms of Use
                    </NavLink>

                </Grid>
            </Grid>

        </footer>
    );
}

export default Footer;
