import React from "react";
import {
  AppBar,
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),

    marginTop: "60px",
    paddingBottom: "70px",
    // minHeight: "600px",
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: "25%",
    "@media (max-width: 901px)": {
      display: "none",
    },
  },
  qrCode: {
    "@media (max-width: 2000px)": {
      maxWidth: "50%",
    },
    "@media (max-width: 1300px)": {
      maxWidth: "50% !important",
    },
    "@media (max-width: 991px)": {
      maxWidth: "40%",
    },
    "@media (max-width: 772px)": {
      maxWidth: "50%",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    background: "linear-gradient(to right, #ff5a5a, #cb5555)",
    backgroundClip: "text",
    marginTop: 0,
    fontWeight: "700 !important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "40px !important",
  },
  appleButton: {
    width: "200px",
    height: "72px",
    backgroundImage:
      "url(https://assets-global.website-files.com/646e79866091ec5584c257d3/646f93c4bad65f90577aac0a_apple-store-badge.png)",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "180px",
    alignSelf: "stretch",
  },
  androidButton: {
    width: "200px",
    height: "72px",
    backgroundImage:
      "url(https://assets-global.website-files.com/646e79866091ec5584c257d3/646f93c4bad65f90577aac0c_google-play-badge.png)",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "180px",
    alignSelf: "stretch",
  },
  // main: {
  //   "@media (max-width: 1300px)": {
  //     maxWidth: "75%",
  //   },
  //   "@media (max-width: 1123px)": {
  //     maxWidth: "100%",
  //   },
  // },
}));

const DownloadAppPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.main}
        margin="auto"
      >
        <Grid
          item
          md={6}
          sm={12}
          marginLeft="60px"
          justifyContent="center"
          textAlign="start"
          className="download-left"
        >
          <img
            src="/images/scanbo-img.png"
            alt="Logo"
            className={classes.logo}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1" className={classes.title}>
              Scan the QR code to download the App
            </Typography>
            <Box style={{ marginTop: "20px" }}>
              <Button
                className={classes.appleButton}
                href="https://apps.apple.com/in/app/scanbo-health/id1462060384"
              ></Button>
              <Button
                className={classes.androidButton}
                href="https://play.google.com/store/apps/details?id=com.scanbo.app&pli=1"
              ></Button>
            </Box>
          </Box>
        </Grid>
        <Grid item textAlign="center" className="download-right" md={5} sm={12}>
          <img src="/images/qr.jpg" alt="QR Code" className={classes.qrCode} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DownloadAppPage;
