// MainLayout.jsx
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResponsiveAppBar from "../Header/Header";
import DotNavigation from "./DotNavigation";

const MainLayout = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(location.pathname);
  const dotNavigationPaths = ["/", "/solutions", "/contactus"];

  const shouldShowDotNavigation = dotNavigationPaths.includes(
    location.pathname
  );

  useEffect(() => {
    setActiveSection(location.pathname);
  }, [location]);

  return (
    <>
      <ResponsiveAppBar
        activeSection={activeSection}
        onSectionChange={setActiveSection}
      />
      {shouldShowDotNavigation && (
        <DotNavigation
          activeSection={activeSection}
          onSectionChange={setActiveSection}
        />
      )}
      {/* Rest of the page content */}
    </>
  );
};

export default MainLayout;
