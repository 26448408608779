import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: "20px auto 0 !important",
    maxWidth: "90%",
    paddingBottom: "70px !important",
  },
  header: {
    margin: theme.spacing(4, 0),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: " rgba(0, 0, 0, 0)",
    background: "linear-gradient(to right, #ff5a5a, #cb5555)",
    backgroundClip: "text",
    marginTop: 0,
    lineHeight: "44px",
    fontWeight: "700 !important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "25px !important",
  },
  section: {
    margin: theme.spacing(2, 0),
    width: "100%",
    maxWidth: "100%",
    color: "#373737",
    textAlign: "left",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "32px !important",
    display: "block",
  },
  disclaimer: {
    marginTop: "20px",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>PRIVACY POLICY</Typography>
      <Box className={classes.disclaimer}>
        <Typography variant="body1" className={classes.section}>
          This Privacy Policy informs you of important information about how
          SCANBO™, and our family of companies (together, “SCANBO” “we” or
          “our”) to process the personal data that we collect in online and
          offline formats through the Services. By providing personal
          information to us or by using the Service, you acknowledge that you
          have read and understand this Privacy Policy.
          <br />
          <br />
          <strong> ‍INFORMATION WE COLLECT</strong>
          <br />
          ‍We collect certain personal data from visitors to our Sites. We
          generally collect this information directly from you when you fill out
          form fields, or provide various information in any form on our
          Website, download product documentation, apply for a job, or register
          for and participate in our medical education services. In this variety
          of different circumstances on the Sites we collect:
          <br />
          Name
          <br />
          Customer account number
          <br />
          Company name
          <br />
          Your photo, if you provide it to us
          <br />
          Email address <br />
          Date of Birth <br />
          Gender <br />
          Weight <br />
          Physical address <br />
          Phone number <br />
          Time zone <br />
          Resume and work history details, if you apply for a job with us <br />
          The products and medical education services you are interested in{" "}
          <br />
          If you submit any personal information relating to another individual
          to us, you represent that you have the authority to do so and to
          permit us to use the information in accordance with this Privacy
          Policy. <br />
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong>LOCATION INFORMATION</strong> <br />
          ‍We collect and use precise location information from SCANBO device
          with your permission when you choose connect SCANBO device to SCANBO
          app using Bluetooth connectivity. We do not share this information
          with any one or third party.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong> ​SENSITIVE INFORMATION</strong> <br />
          ‍It is presumed that you do not provide any sensitive information to
          us, unless we specifically asked you to provide the sensitive
          information. We may classify any information as sensitive, however the
          same shall be specifically stated to be classified.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong> ‍HOW TO USE COOKIES :</strong> <br />
          ‍We use cookies and related technologies (“Cookies”) to provide
          Services, gather information when users navigate through the Sites to
          enhance and personalize the experience, to understand usage patterns,
          and to improve our Sites, products, and Services. <br />
          User grants us the right to use, copy, store, transmit and display
          User data solely to the extent necessary to provide the Platform and
          associated maintenance, for purposes of ensuring security, and for the
          investigation of complaints. All data submitted by User to the
          Platform (User Data) will remain the sole property of User. Subject to
          the terms of this Agreement, we shall not disclose User Data or use
          User data for any purpose other than to provide the Platform and as
          set forth herein. <br />
          The Platform uses "cookies", which are small text files placed on the
          User’s hard disk by a Web server. Cookies contain information that can
          later be read by a Web server in the domain that issued the cookie to
          the User. One of the primary purposes of cookies is to store the
          User’s preferences and other information on the User’s computer in
          order to save the User’s time by eliminating the need to repeatedly
          enter the same information and to display the User’s personalized
          content and targeted advertising on the User’s later visits to this
          Platform. When the User signs in the Platform named User account, we
          store the User’s unique ID number, and the time the User signed in, in
          an encrypted cookie on the User’s a hard disk. This cookie allows the
          User to move from page to page at the Platform without having to sign
          in again on each page. When the User signs out, these cookies are
          deleted from the User’s computer. We also use cookies to improve the
          sign-in experience. User agrees that we may use Usage Statistics to
          provide Users with communications regarding Platform support, news,
          and information regarding the Platform and services/products provided
          by us, except where such Users opt out of receiving such information.
          We may ask you to provide information SCANBO may ask you to provide
          personal information for purposes including, but not limited to, the
          following: Buying SCANBO products and services; Activating or
          registering certain products and services or enhancing functionality;
          Receiving information about SCANBO products and services;
          Participating in SCANBO online communities, including our social media
          channels/pages; Storing your preferences for future interactions and
          communications from SCANBO; Helping us to develop products and
          services and create campaigns that are designed around you, optimize
          customer services and continuously improve our websites; Helping us to
          improve products and services, and allowing SCANBO to keep you
          informed of, or involve you in the testing of, new products and
          services; Resolving consumer and/or product and services issues;
          Registering visitors of SCANBO facilities or SCANBO organized events
          and conferences; Contract or tender management; and Receiving
          personalized messages, special offers, and advertisements that are
          relevant to your personal interests, based on the information you have
          shared with us and on the information we have collected through
          cookies or similar techniques regarding your use of the SCANBO
          websites/social media/blogs. We may also collect product and service
          information and provide these statistics to others in an aggregate
          form where the information has been de-identified.
        </Typography>
        <br />
        <br />

        <Typography variant="body1" className={classes.section}>
          <strong> HOW WE USE INFORMATION</strong> <br />
          ‍We use and disclose information you provide to us as described to you
          at the point of collection.We disclose the information for the
          following purposes:To pursue our legitimate interests of operating and
          growing our business, operating and improving the Sites, delivering
          the Customer Services and engaging in Marketing Activities; andYour
          consent, where we make it clear to you in advance that we are relying
          on your consent (for example, when you sign up to our mailing
          list).SCANBO may disclose any information we have about you (including
          your identity) if we determine that such disclosure is necessary for
          connection with any investigation or complaint regarding your use of
          the Site, or to identify, contact or bring legal action against
          someone who may be causing injury to or interference with (either
          intentionally or unintentionally) SCANBO’S rights or property, or the
          rights or property of visitors to or users including SCANBO’S
          customers. We reserve the right at all times to disclose any
          information that deems necessary to comply with any applicable law,
          regulation, legal process or governmental request. The website doesn't
          accept any forms of cash advertising, sponsorship, paid insertions or
          other forms of compensation. The Company reserves the right to change
          the Disclaimer/Privacy Policy under which this web site is offered at
          any time and without notice. You will be automatically bound by these
          modifications when you use this site, and should periodically read the
          Disclaimer & Privacy Policy. After having read and understood the
          disclaimer and privacy notice, I hereby agree to accept and abide by
          all the terms and conditions as mentioned therein at my sole
          responsibility. Further, the Information provided by you can be used
          for the following activities: <br />
          to provide the functionality of the Service to you and providing you
          with related customer service for the services provided by Scanbo{" "}
          <br />
          to respond to your inquiries and fulfill your requests for the
          services provided by Scanbo; <br />
          We will engage in these activities to manage our contractual
          relationship with you and/or to comply with a legal obligation.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong> CROSS BORDER TRANSFER</strong>
          <br />
          ‍The information provided by you may be saved, stored and processed in
          any country where we have facilities or service providers, and by
          using our Service or by providing consent to us (where required by
          law), your information may be transferred to countries outside of your
          country of residence or place where you have filled such information.
          The laws of Data Protection shall be binding on us where the Data is
          stored and processed and where appropriate contractual and other
          measures are in place to protect personal information when it is
          transferred to our affiliates or third parties in other countries.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong>‍SECURITY</strong>
          <br />
          ‍Though we have taken all reasonable organizational, technical, and
          administrative measures designed to protect personal information under
          our control. However, no data transmission over the Internet or data
          storage system can be guaranteed to be 100% secure. If you have reason
          to believe that your interaction with us is no longer secure (for
          example, if you feel that the security of any account you have with us
          has been compromised), please immediately notify us in accordance with
          the “Contacting Us” section below.
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong>RETENTION PERIOD</strong> ‍
          <br />
          ‍We will retain your personal information for as long as needed by
          Scanbo. The criteria used to determine our retention periods include:
          (i) the length of time we have an ongoing relationship with you and
          provide the Service to you; (ii) whether there is a legal obligation
          to which we are subject; and (iii) whether retention is advisable in
          light of our legal position (such as in regard to applicable statutes
          of limitations, litigation, or regulatory investigations). ‍
        </Typography>
        <br />
        <br />
        <Typography variant="body1" className={classes.section}>
          <strong>UPDATES TO THIS PRIVACY POLICIY</strong>
          <br />
          ‍We may change this Privacy Policy. Any changes to this Privacy Policy
          will become effective when we post the revised Privacy Policy on the
          Service. Your use of the Service following these changes means that
          you accept the revised Privacy Policy. We recommend that you regularly
          review the Privacy Policy when you visit the Service.
        </Typography>
        {/* Repeat for each section of the disclaimer */}
      </Box>
      {/* Additional content if needed */}
    </div>
  );
};

export default PrivacyPolicy;
